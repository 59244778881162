import { Sunburst } from "@ant-design/plots";
import { Card, Col, Row, Typography } from "antd";
import { useState, useEffect } from "react";
import "./DonutChart";

const { Text } = Typography;

const Donut = ({
  genero,
  filters,
  data,
  banderaGenero,
  showLoadingData,
  porcentajeAudience
}) => {
  const [config, setConfig] = useState();
  const [textColor, setTextColor] = useState('#00C6A2');

  useEffect(() => {
    if (data) {
      const colorMapping = {
        'Audiencia': '#391EDE',
        'Nueva audiencia': '#FF596F',
        'BD externa': '#00C6A2',
      };
  
      // Verificamos si hay children dentro de data y si el primer children tiene un tipo definido
      if (data.children && data.children.length > 0 && data.children[0].type) {
        const firstChild = data.children[0];
        const textColor = colorMapping[firstChild.type];
  
        let config = {
          data,
          innerRadius: 0.01,
          hierarchyConfig: {
            field: 'subscribers',
          },
          colorField: 'type',
          color: textColor ? [colorMapping['Audiencia'], data.children.some(item => item.children && item.children.some(child => child.type === 'BD externa')) ? colorMapping['BD externa']: colorMapping['Nueva audiencia'], textColor] : null,
          interactions: [],
          tooltip: {
            fields: ['type', 'subscribers'],
            formatter: (datum) => ({
              name: datum.type,
              value: datum.subscribers,
            }),
          },
        };
  
        setConfig(
          <Sunburst {...config} />
        );
      }
      const hasBDExterna = data.children.some(item => item.children && item.children.some(child => child.type === 'BD externa'));
      setTextColor(hasBDExterna ? '#00C6A2' : '#FF596F');
    }
  }, [data]);

  return (
    <>
      <Card
        style={{
          width: "100%",
          padding: 0,
          borderRadius: 20,
          backgroundColor: "#F8FAFF",
          boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="SubtituloCharts" style={{ color: "#0D33B9"}}>
          {
            banderaGenero === 'hombre' ?
              <>
                Hombre
                <br />
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: 270 }} > <g clipPath="url(#clip0_1128_13267)"> <path d="M0 32.6562L32 32.6562L32 0.65625L0 0.65625L0 32.6562Z" fill="white" fillOpacity="0.01" /> <path d="M4.03223 22.6239L4.03223 28.6239H10.0322" stroke="#391EDE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> <path d="M25.0583 7.32222C21.4135 3.67735 15.5039 3.67735 11.859 7.32222C10.0365 9.14468 9.12533 11.5333 9.12533 13.9219C9.12533 16.3105 10.0365 18.6991 11.859 20.5215C15.5039 24.1665 21.4135 24.1665 25.0583 20.5215C28.7032 16.8767 28.7032 10.9671 25.0583 7.32222Z" stroke="#0D33B9" strokeWidth="2" strokeLinejoin="round" /> <path d="M12 20.6562L5.3656 27.2905" stroke="#0D33B9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> </g> <defs> <clipPath id="clip0_1128_13267"> <rect width="32" height="32" fill="white" transform="translate(32 32.6562) rotate(-180)" /> </clipPath> </defs> </svg>
              </>
              :
              banderaGenero === 'mujer' ?
                <>
                  Mujer
                  <br />
                  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: 270 }} > <g clipPath="url(#clip0_1128_13266)"> <path fillRule="evenodd" clipRule="evenodd" d="M16 2.65625C13.8783 2.65625 11.8434 3.49911 10.3431 4.9994C8.84285 6.49969 8 8.53452 8 10.6563C8 12.778 8.84285 14.8128 10.3431 16.3131C11.8434 17.8134 13.8783 18.6563 16 18.6563C18.1217 18.6563 20.1566 17.8134 21.6569 16.3131C23.1571 14.8128 24 12.778 24 10.6563C24 8.53452 23.1571 6.49969 21.6569 4.9994C20.1566 3.49911 18.1217 2.65625 16 2.65625ZM6 10.6563C6.00002 8.72108 6.56154 6.82748 7.61645 5.20512C8.67137 3.58276 10.1743 2.30134 11.9431 1.51626C13.7119 0.731185 15.6705 0.476187 17.5813 0.782192C19.4921 1.0882 21.2731 1.94206 22.7083 3.24022C24.1434 4.53838 25.1711 6.22507 25.6666 8.09573C26.1621 9.96638 26.1042 11.9406 25.4999 13.779C24.8957 15.6174 23.7709 17.241 22.2622 18.4529C20.7534 19.6647 18.9255 20.4127 17 20.6063V24.6563H21C21.2652 24.6563 21.5196 24.7616 21.7071 24.9491C21.8946 25.1367 22 25.391 22 25.6563C22 25.9215 21.8946 26.1758 21.7071 26.3634C21.5196 26.5509 21.2652 26.6563 21 26.6563H17V31.6563C17 31.9215 16.8946 32.1758 16.7071 32.3634C16.5196 32.5509 16.2652 32.6563 16 32.6563C15.7348 32.6563 15.4804 32.5509 15.2929 32.3634C15.1054 32.1758 15 31.9215 15 31.6563V26.6563H11C10.7348 26.6563 10.4804 26.5509 10.2929 26.3634C10.1054 26.1758 10 25.9215 10 25.6563C10 25.391 10.1054 25.1367 10.2929 24.9491C10.4804 24.7616 10.7348 24.6563 11 24.6563H15V20.6063C12.5334 20.3583 10.2467 19.2031 8.58376 17.3645C6.92077 15.526 5.99997 13.1353 6 10.6563Z" fill="#0D33B9" /> </g> <defs> <clipPath id="clip0_1128_13266"> <rect width="32" height="35" fill="white" transform="translate(0 0.65625)" /> </clipPath> </defs> </svg>
                </>
                :
                <>
                  Desconocido
                  <br />
                  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: 270 }} ></svg>
                </>
          }
        </div>
        Porcentaje de suscriptores
        <Row>
          <Col xs={24} sm={24} md={6} lg={6} style={{ marginTop: -7 }} align="center">
            <br />
            <br />
            <Text
              className="SubmenuRegularGA"
              style={{ color: "#391EDE", fontSize: "20px" }}
              loading={showLoadingData}
            >
              {" "}
              {porcentajeAudience[0]}
            </Text>
            {/*<PorcentajeAudiencia data={data} showLoadingData={showLoadingData}/>*/}
            <br />
            <Text
              className="SubmenuRegularGA"
              style={{ color: textColor, fontSize: "20px" }}
              loading={showLoadingData}
            >
              {" "}
              {porcentajeAudience[1]}
            </Text>
            {/*<PorcentajeNuevaAudiencia data={data} tooltip={tooltip} showLoadingData={showLoadingData}/>*/}
            <br />
            {/*<PorcentajeBDexterna data={data} tooltip={tooltip} showLoadingData={showLoadingData}/>*/}
          </Col>
          <Col xs={24} sm={24} md={18} lg={18}>
            <div className="donut-chart-container" loading={showLoadingData}>
              {config && config}
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Donut;
