import { useNavigate } from 'react-router-dom'
import  "../App.css"

function NotFoundPage() {
    const navigate = useNavigate();

    return (
        <div className="not-found-container">
            <img
                src="/images/header/404.png"
                alt="Página no encontrada"
                className="not-found-image"
            />
            <h1 className="not-found-title">Página no encontrada</h1>
            <p className="not-found-text">
                La página no se ha encontrado o no tienes permisos para visualizarla.
            </p>
            <div className="not-found-button-container">
                <button className="not-found-button-return" onClick={() => navigate('/')}>
                    Ir a Home
                </button>
                
            </div>
        </div>
    );
}

export default NotFoundPage;
