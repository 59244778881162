import { useEffect, useState, useContext, useRef } from "react";
import {
  Col,
  Row,
  Space,
  Table,
  Button,
  Input,
  Menu,
  Dropdown,
  Modal,
  Card,
  Typography,
  Pagination,
  Switch,
  Spin,
  message,
} from "antd";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { UserContext } from "../../GestorContext";
import "./ListAudience.css";
import { SearchOutlined } from "@ant-design/icons";
import ResponsiveVisibility from "../common/ResponsiveVisibility";

const { Search } = Input;
const { Text, Title } = Typography;
const ListAudience = () => {
  const [data, setData] = useState();
  const [dataExport, setDataExport] = useState();
  const [grid, setGrid] = useState();
  const [typeGrid, setTypeGrid] = useState("list");
  const [itemsMenu, setItemsMenu] = useState();
  const [showLoadingData, setShowLoadingData] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [permission, setPermission] = useState();
  const [idAudience, setIdAudience] = useState();
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const [isModalExport, setIsModalExport] = useState(false);
  const [isModalOpenUpdate, setIsModalOpenUpdate] = useState(false);
  const [isModalListExport, setisModalListExport] = useState(false);
  const [isModalDownload, setIsModalDownload] = useState(false);
  const [rolUser, setRolUser] = useState();
  const [relationexport, setRelationExport] = useState();
  const [searchText,setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [seledRowsExports, setSeledRowsExport ] = useState();
  const [selectedRowKeys, setSelectedRowKeys ] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  
  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage] = useState(12);
  const pako = require('pako');

  const searchInput = useRef(null);
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  var date = new Date();
  var year = date.getFullYear();
  var month = (1 + date.getMonth()).toString().padStart(2, '0');
  var day = date.getDate().toString().padStart(2, '0');
  var formattedDate = year + '/' + month + '/' + day;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div
        style={{
          padding: 8
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block"
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              borderRadius:"10px",
              backgroundColor: "#391EDE"
            }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
              borderRadius:"10px",
            }}
          >
            Limpiar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
      text
      ) : (
        text
      )
  });

  useEffect(() => {
    if (userContext) {
      const groups =
        userContext.signInUserSession.idToken.payload["cognito:groups"];
      setRolUser(groups[0]);
    }
  }, []);

  const fetchData = async (variables) => {
    let nextToken = null;
    let allAudiences = [];
 
    do {
      let query1;
 
      if (rolUser === "Admin" || rolUser === "Customer") {
        query1 = `
        query ListAudiences(
            $filter: ModelAudienceFilterInput
            $limit: Int
            $nextToken: String
        ) {
            listAudiences(filter: $filter, limit: $limit, nextToken: $nextToken) {
                items {
                    updatedAt
                    name
                    isPublic
                    id
                    count
                    user {
                        username
                        groups {
                            items {
                                group {
                                    name
                                }
                            }
                        }
                    }
                    filters {
                        value_filter
                        type_filter
                    }
                    audienceClmId
                    clm {
                        name
                    }
                }
                nextToken
            }
          }`;
      } else {
        // preguntar a issa si se manejara para otro roly
        query1 = `
        query ListAudiences(
            $filter: ModelAudienceFilterInput
            $limit: Int
            $nextToken: String
        ) {
            listAudiences(filter: $filter, limit: $limit, nextToken: $nextToken) {
              items {
                  updatedAt
                  name
                  isPublic
                  id
                  count
                  user {
                    username
                    groups {
                      items {
                        group {
                            name
                        }
                      }
                    }
                  }
                  filters {
                      value_filter
                      type_filter
                  }
              }
              nextToken
            }
          }`;
        }

      const response = await API.graphql({
        query: query1,
        variables: { ...variables, nextToken },
      });
 
      const filteredItems = response.data.listAudiences.items;
 
      // Filtrar y eliminar los objetos nulos
      const items = filteredItems.filter(item => item !== null);

      for (const item of items) {
        for (const filter of item.filters) {
          if (filter.type_filter === -1) {
            try {
              const base64DecodedData = atob(filter.value_filter);
              const compressedData = Uint8Array.from([...base64DecodedData].map((c) => c.charCodeAt(0)));
              const decompressedData = pako.inflate(compressedData, { to: 'string' });
              const utf8DecodedString = decodeURIComponent(decompressedData);
              const obj = eval('(' + utf8DecodedString + ')');
              const transformedData = [];
              for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                  const entry = obj[key];
                  const { value_filter, type_filter } = entry;
                  transformedData.push({ value_filter, type_filter });
                }
              }

              item.filters=transformedData

            } catch (error) {
              console.error("Error al descomprimir el value_filter:", error);
            }
          }
        }
      }
      allAudiences = allAudiences.concat(items);
      nextToken = response.data.listAudiences.nextToken;
    } while (nextToken !== null);
    setData(allAudiences.length > 0 ? allAudiences : undefined);
    setShowLoadingData(false);
  };
  

  useEffect(() => {
    if (!isModalOpenUpdate && !isModalOpenDelete) {
      setShowLoadingData(true);
      const filter = searchValue ? { name: { contains: searchValue } } : null;
      switch (rolUser) {
        case "Admin":
          fetchData({
            filter: {
              is_deleted: { eq: false },
              ...filter,
            },
            limit: 2000,
          });
          setItemsMenu([
            {
              key: "1",
              label: "Ver / Editar",
              icon: (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 18 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="Fill 1"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.8334 14.6265H8.99805C8.72205 14.6265 8.49805 14.4025 8.49805 14.1265C8.49805 13.8505 8.72205 13.6265 8.99805 13.6265H13.8334C14.1094 13.6265 14.3334 13.8505 14.3334 14.1265C14.3334 14.4025 14.1094 14.6265 13.8334 14.6265Z"
                    fill="black"
                  />
                  <path
                    id="Clip 4"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.33398 2H12.7877V14.6264H1.33398V2Z"
                    fill="white"
                  />
                  <path
                    id="Fill 3"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.74083 3.34443L2.46417 11.1944C2.35017 11.3371 2.30817 11.5211 2.35017 11.6978L2.80417 13.6211L4.83017 13.5958C5.02283 13.5938 5.20083 13.5078 5.31883 13.3611C7.4635 10.6778 11.5522 5.56176 11.6682 5.41176C11.7775 5.23443 11.8202 4.98376 11.7628 4.74243C11.7042 4.4951 11.5502 4.2851 11.3282 4.1511C11.2808 4.11843 10.1575 3.24643 10.1228 3.2191C9.70017 2.88043 9.0835 2.9391 8.74083 3.34443ZM2.4095 14.6264C2.17817 14.6264 1.97683 14.4678 1.92283 14.2418L1.37683 11.9278C1.26417 11.4484 1.37617 10.9538 1.6835 10.5698L7.9635 2.7151C7.96617 2.71243 7.96817 2.7091 7.97083 2.70643C8.6595 1.8831 9.90483 1.76176 10.7448 2.43576C10.7782 2.46176 11.8935 3.32843 11.8935 3.32843C12.2988 3.56976 12.6155 4.0011 12.7355 4.51176C12.8548 5.0171 12.7682 5.53843 12.4902 5.97909C12.4695 6.01176 12.4515 6.03976 6.0995 13.9864C5.7935 14.3678 5.33483 14.5898 4.84217 14.5958L2.41617 14.6264H2.4095Z"
                    fill="black"
                  />
                  <path
                    id="Fill 6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.8163 7.78983C10.7096 7.78983 10.6029 7.75583 10.5116 7.6865L6.87693 4.8945C6.65826 4.7265 6.61693 4.41316 6.78493 4.19316C6.9536 3.9745 7.26693 3.93383 7.48626 4.10183L11.1216 6.89316C11.3403 7.06116 11.3816 7.37516 11.2129 7.5945C11.1149 7.7225 10.9663 7.78983 10.8163 7.78983Z"
                    fill="black"
                  />
                </svg>
              ),
            },
            {
              key: "2",
              label: "Exportar",
              icon: (
                <svg
                  width="16"
                  height="16"
                  viewBox="1 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.666 5.86279H4.33295V15.0255H17.666V5.86279Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.28935 15.0255H14.7093C16.3393 15.0255 17.666 13.6995 17.666 12.0688V8.81879C17.666 7.18879 16.3393 5.86279 14.7093 5.86279H14.0873C13.8113 5.86279 13.5873 6.08679 13.5873 6.36279C13.5873 6.63879 13.8113 6.86279 14.0873 6.86279H14.7093C15.7887 6.86279 16.666 7.74013 16.666 8.81879V12.0688C16.666 13.1481 15.7887 14.0255 14.7093 14.0255H7.28935C6.21068 14.0255 5.33268 13.1481 5.33268 12.0688V8.81279C5.33268 7.73746 6.20735 6.86279 7.28202 6.86279H7.91068C8.18668 6.86279 8.41068 6.63879 8.41068 6.36279C8.41068 6.08679 8.18668 5.86279 7.91068 5.86279H7.28202C5.65602 5.86279 4.33268 7.18613 4.33268 8.81279V12.0688C4.33268 13.6995 5.65935 15.0255 7.28935 15.0255"
                    fill="black"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.5 1.3335H10.5V10.3608H11.5V1.3335Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11 10.3608C11.276 10.3608 11.5 10.1368 11.5 9.86083V1.8335C11.5 1.5575 11.276 1.3335 11 1.3335C10.724 1.3335 10.5 1.5575 10.5 1.8335V9.86083C10.5 10.1368 10.724 10.3608 11 10.3608"
                    fill="black"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.9435 4.28601C13.0709 4.28601 13.1989 4.23734 13.2962 4.14001C13.4915 3.94534 13.4929 3.62934 13.2975 3.43334L11.3542 1.48134C11.1669 1.29268 10.8335 1.29268 10.6462 1.48134L8.70219 3.43334C8.50752 3.62934 8.50819 3.94534 8.70352 4.14001C8.89952 4.33468 9.21552 4.33468 9.41019 4.13868L11.0002 2.54268L12.5895 4.13868C12.6869 4.23734 12.8155 4.28601 12.9435 4.28601"
                    fill="black"
                  />
                </svg>
              ),
            },
            {
              key: "3",
              label: "Permiso",
              icon: (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="Clip 2"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.33398 9.30469H11.4565V14.0378H1.33398V9.30469Z"
                    fill="white"
                  />
                  <path
                    id="Fill 1"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.39532 10.3047C4.54265 10.3047 2.33398 10.5427 2.33398 11.6794C2.33398 12.5807 3.70065 13.038 6.39532 13.038C9.08998 13.038 10.4567 12.576 10.4567 11.666C10.4567 10.7627 9.08998 10.3047 6.39532 10.3047ZM6.39532 14.038C5.01132 14.038 1.33398 14.038 1.33398 11.6794C1.33398 9.30469 5.14332 9.30469 6.39532 9.30469C8.56798 9.30469 11.4567 9.54935 11.4567 11.666C11.4567 14.038 7.64732 14.038 6.39532 14.038Z"
                    fill="black"
                  />
                  <path
                    id="Fill 4"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.3947 2.33325C5.05804 2.33325 3.9707 3.42059 3.9707 4.75659C3.9707 6.09259 5.05804 7.17992 6.3947 7.17992H6.41537C7.05804 7.17725 7.66337 6.92458 8.11737 6.46658C8.57204 6.00992 8.8207 5.40259 8.81804 4.75858C8.81804 3.42059 7.7307 2.33325 6.3947 2.33325ZM6.3947 8.17992C4.5067 8.17992 2.9707 6.64392 2.9707 4.75659C2.9707 2.86925 4.5067 1.33325 6.3947 1.33325C8.28204 1.33325 9.81804 2.86925 9.81804 4.75659C9.82204 5.66592 9.46937 6.52458 8.8267 7.17125C8.18537 7.81858 7.3287 8.17659 6.41737 8.17992H6.3947Z"
                    fill="black"
                  />
                  <path
                    id="Fill 6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.9897 7.42118C10.7444 7.42118 10.5304 7.24051 10.4951 6.99051C10.4571 6.71718 10.6471 6.46384 10.9204 6.42584C11.7524 6.30918 12.3804 5.58718 12.3817 4.74584C12.3817 3.90984 11.7831 3.20451 10.9597 3.06984C10.6871 3.02451 10.5024 2.76784 10.5471 2.49518C10.5917 2.22251 10.8497 2.03918 11.1211 2.08251C12.4311 2.29718 13.3817 3.41784 13.3817 4.74651C13.3791 6.08318 12.3811 7.23118 11.0597 7.41651C11.0364 7.41984 11.0131 7.42118 10.9897 7.42118Z"
                    fill="black"
                  />
                  <path
                    id="Clip 9"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.8984 8.98877H14.6543V12.3071H11.8984V8.98877Z"
                    fill="white"
                  />
                  <path
                    id="Fill 8"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.2653 12.3071C13.0633 12.3071 12.8733 12.1838 12.798 11.9845C12.7 11.7265 12.83 11.4371 13.088 11.3398C13.6546 11.1251 13.6546 10.8631 13.6546 10.7511C13.6546 10.3731 13.2073 10.1145 12.3253 9.98312C12.052 9.94179 11.8633 9.68712 11.904 9.41446C11.9453 9.14112 12.2046 8.95779 12.4726 8.99312C14.2766 9.26312 14.6546 10.0985 14.6546 10.7511C14.6546 11.2371 14.4446 11.8951 13.4426 12.2745C13.3846 12.2965 13.3246 12.3071 13.2653 12.3071Z"
                    fill="black"
                  />
                </svg>
              ),
            },
            {
              key: "4",
              label: "Descargar",
              icon: (
                <svg width="16" height="16" viewBox="4 4 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 15.9853L15.182 12.8033L14.1213 11.7427L12.75 13.114L12.75 5.25L11.25 5.25L11.25 13.114L9.8787 11.7427L8.81804 12.8033L12 15.9853ZM12 13.864L12 13.864L12.0001 13.864L12 13.864Z" fill="black"/>
                <path d="M18 17.25L18 18.75L6 18.75L6 17.25L18 17.25Z" fill="black"/>
                </svg>
              ),
            },
            {
              key: "5",
              label: "Eliminar",
              icon: (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.16359 14.6666C7.26025 14.6666 6.37959 14.6566 5.50825 14.6386C4.39358 14.6166 3.62225 13.8939 3.49625 12.7526C3.28625 10.8593 2.92692 6.3966 2.92358 6.35194C2.90092 6.0766 3.10625 5.83527 3.38159 5.81327C3.65292 5.80594 3.89825 5.9966 3.92025 6.27127C3.92359 6.3166 4.28225 10.7639 4.49025 12.6426C4.56159 13.2913 4.91158 13.6259 5.52892 13.6386C7.19559 13.6739 8.89625 13.6759 10.7296 13.6426C11.3856 13.6299 11.7403 13.3019 11.8136 12.6379C12.0203 10.7753 12.3803 6.3166 12.3843 6.27127C12.4063 5.9966 12.6496 5.8046 12.9223 5.81327C13.1976 5.83594 13.4029 6.0766 13.3809 6.35194C13.3769 6.39727 13.0156 10.8713 12.8076 12.7479C12.6783 13.9126 11.9089 14.6213 10.7476 14.6426C9.85892 14.6579 9.00159 14.6666 8.16359 14.6666Z"
                    fill="black"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.8053 4.65942H2.5C2.224 4.65942 2 4.43542 2 4.15942C2 3.88342 2.224 3.65942 2.5 3.65942H13.8053C14.0813 3.65942 14.3053 3.88342 14.3053 4.15942C14.3053 4.43542 14.0813 4.65942 13.8053 4.65942Z"
                    fill="black"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.6264 4.6595C10.8677 4.6595 10.2091 4.11883 10.0597 3.37483L9.89774 2.56416C9.86374 2.44083 9.72307 2.3335 9.56307 2.3335H6.74107C6.58107 2.3335 6.4404 2.44083 6.39973 2.59483L6.2444 3.37483C6.09573 4.11883 5.4364 4.6595 4.67773 4.6595C4.40173 4.6595 4.17773 4.4355 4.17773 4.1595C4.17773 3.8835 4.40173 3.6595 4.67773 3.6595C4.96173 3.6595 5.2084 3.45683 5.2644 3.17816L5.4264 2.3675C5.59107 1.74616 6.12907 1.3335 6.74107 1.3335H9.56307C10.1751 1.3335 10.7131 1.74616 10.8711 2.3375L11.0404 3.17816C11.0957 3.45683 11.3424 3.6595 11.6264 3.6595C11.9024 3.6595 12.1264 3.8835 12.1264 4.1595C12.1264 4.4355 11.9024 4.6595 11.6264 4.6595Z"
                    fill="black"
                  />
                </svg>
              ),
            }
          ]);
          break;
        case "Company":
          fetchData({
            filter: {
              is_deleted: { eq: false },
              isPublic: { eq: true },
              ...filter,
            },
            limit: 2000,
          });
          setItemsMenu([
            {
              key: "1",
              label: "Ver / Editar",
              icon: (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 18 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="Fill 1"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.8334 14.6265H8.99805C8.72205 14.6265 8.49805 14.4025 8.49805 14.1265C8.49805 13.8505 8.72205 13.6265 8.99805 13.6265H13.8334C14.1094 13.6265 14.3334 13.8505 14.3334 14.1265C14.3334 14.4025 14.1094 14.6265 13.8334 14.6265Z"
                    fill="black"
                  />
                  <path
                    id="Clip 4"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.33398 2H12.7877V14.6264H1.33398V2Z"
                    fill="white"
                  />
                  <path
                    id="Fill 3"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.74083 3.34443L2.46417 11.1944C2.35017 11.3371 2.30817 11.5211 2.35017 11.6978L2.80417 13.6211L4.83017 13.5958C5.02283 13.5938 5.20083 13.5078 5.31883 13.3611C7.4635 10.6778 11.5522 5.56176 11.6682 5.41176C11.7775 5.23443 11.8202 4.98376 11.7628 4.74243C11.7042 4.4951 11.5502 4.2851 11.3282 4.1511C11.2808 4.11843 10.1575 3.24643 10.1228 3.2191C9.70017 2.88043 9.0835 2.9391 8.74083 3.34443ZM2.4095 14.6264C2.17817 14.6264 1.97683 14.4678 1.92283 14.2418L1.37683 11.9278C1.26417 11.4484 1.37617 10.9538 1.6835 10.5698L7.9635 2.7151C7.96617 2.71243 7.96817 2.7091 7.97083 2.70643C8.6595 1.8831 9.90483 1.76176 10.7448 2.43576C10.7782 2.46176 11.8935 3.32843 11.8935 3.32843C12.2988 3.56976 12.6155 4.0011 12.7355 4.51176C12.8548 5.0171 12.7682 5.53843 12.4902 5.97909C12.4695 6.01176 12.4515 6.03976 6.0995 13.9864C5.7935 14.3678 5.33483 14.5898 4.84217 14.5958L2.41617 14.6264H2.4095Z"
                    fill="black"
                  />
                  <path
                    id="Fill 6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.8163 7.78983C10.7096 7.78983 10.6029 7.75583 10.5116 7.6865L6.87693 4.8945C6.65826 4.7265 6.61693 4.41316 6.78493 4.19316C6.9536 3.9745 7.26693 3.93383 7.48626 4.10183L11.1216 6.89316C11.3403 7.06116 11.3816 7.37516 11.2129 7.5945C11.1149 7.7225 10.9663 7.78983 10.8163 7.78983Z"
                    fill="black"
                  />
                </svg>
              ),
            },
            {
              key: "2",
              label: "Exportar",
              icon: (
                <svg
                  width="16"
                  height="16"
                  viewBox="1 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.666 5.86279H4.33295V15.0255H17.666V5.86279Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.28935 15.0255H14.7093C16.3393 15.0255 17.666 13.6995 17.666 12.0688V8.81879C17.666 7.18879 16.3393 5.86279 14.7093 5.86279H14.0873C13.8113 5.86279 13.5873 6.08679 13.5873 6.36279C13.5873 6.63879 13.8113 6.86279 14.0873 6.86279H14.7093C15.7887 6.86279 16.666 7.74013 16.666 8.81879V12.0688C16.666 13.1481 15.7887 14.0255 14.7093 14.0255H7.28935C6.21068 14.0255 5.33268 13.1481 5.33268 12.0688V8.81279C5.33268 7.73746 6.20735 6.86279 7.28202 6.86279H7.91068C8.18668 6.86279 8.41068 6.63879 8.41068 6.36279C8.41068 6.08679 8.18668 5.86279 7.91068 5.86279H7.28202C5.65602 5.86279 4.33268 7.18613 4.33268 8.81279V12.0688C4.33268 13.6995 5.65935 15.0255 7.28935 15.0255"
                    fill="black"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.5 1.3335H10.5V10.3608H11.5V1.3335Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11 10.3608C11.276 10.3608 11.5 10.1368 11.5 9.86083V1.8335C11.5 1.5575 11.276 1.3335 11 1.3335C10.724 1.3335 10.5 1.5575 10.5 1.8335V9.86083C10.5 10.1368 10.724 10.3608 11 10.3608"
                    fill="black"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.9435 4.28601C13.0709 4.28601 13.1989 4.23734 13.2962 4.14001C13.4915 3.94534 13.4929 3.62934 13.2975 3.43334L11.3542 1.48134C11.1669 1.29268 10.8335 1.29268 10.6462 1.48134L8.70219 3.43334C8.50752 3.62934 8.50819 3.94534 8.70352 4.14001C8.89952 4.33468 9.21552 4.33468 9.41019 4.13868L11.0002 2.54268L12.5895 4.13868C12.6869 4.23734 12.8155 4.28601 12.9435 4.28601"
                    fill="black"
                  />
                </svg>
              ),
            },
            {
              key: "3",
              label: "Permiso",
              icon: (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="Clip 2"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.33398 9.30469H11.4565V14.0378H1.33398V9.30469Z"
                    fill="white"
                  />
                  <path
                    id="Fill 1"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.39532 10.3047C4.54265 10.3047 2.33398 10.5427 2.33398 11.6794C2.33398 12.5807 3.70065 13.038 6.39532 13.038C9.08998 13.038 10.4567 12.576 10.4567 11.666C10.4567 10.7627 9.08998 10.3047 6.39532 10.3047ZM6.39532 14.038C5.01132 14.038 1.33398 14.038 1.33398 11.6794C1.33398 9.30469 5.14332 9.30469 6.39532 9.30469C8.56798 9.30469 11.4567 9.54935 11.4567 11.666C11.4567 14.038 7.64732 14.038 6.39532 14.038Z"
                    fill="black"
                  />
                  <path
                    id="Fill 4"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.3947 2.33325C5.05804 2.33325 3.9707 3.42059 3.9707 4.75659C3.9707 6.09259 5.05804 7.17992 6.3947 7.17992H6.41537C7.05804 7.17725 7.66337 6.92458 8.11737 6.46658C8.57204 6.00992 8.8207 5.40259 8.81804 4.75858C8.81804 3.42059 7.7307 2.33325 6.3947 2.33325ZM6.3947 8.17992C4.5067 8.17992 2.9707 6.64392 2.9707 4.75659C2.9707 2.86925 4.5067 1.33325 6.3947 1.33325C8.28204 1.33325 9.81804 2.86925 9.81804 4.75659C9.82204 5.66592 9.46937 6.52458 8.8267 7.17125C8.18537 7.81858 7.3287 8.17659 6.41737 8.17992H6.3947Z"
                    fill="black"
                  />
                  <path
                    id="Fill 6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.9897 7.42118C10.7444 7.42118 10.5304 7.24051 10.4951 6.99051C10.4571 6.71718 10.6471 6.46384 10.9204 6.42584C11.7524 6.30918 12.3804 5.58718 12.3817 4.74584C12.3817 3.90984 11.7831 3.20451 10.9597 3.06984C10.6871 3.02451 10.5024 2.76784 10.5471 2.49518C10.5917 2.22251 10.8497 2.03918 11.1211 2.08251C12.4311 2.29718 13.3817 3.41784 13.3817 4.74651C13.3791 6.08318 12.3811 7.23118 11.0597 7.41651C11.0364 7.41984 11.0131 7.42118 10.9897 7.42118Z"
                    fill="black"
                  />
                  <path
                    id="Clip 9"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.8984 8.98877H14.6543V12.3071H11.8984V8.98877Z"
                    fill="white"
                  />
                  <path
                    id="Fill 8"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.2653 12.3071C13.0633 12.3071 12.8733 12.1838 12.798 11.9845C12.7 11.7265 12.83 11.4371 13.088 11.3398C13.6546 11.1251 13.6546 10.8631 13.6546 10.7511C13.6546 10.3731 13.2073 10.1145 12.3253 9.98312C12.052 9.94179 11.8633 9.68712 11.904 9.41446C11.9453 9.14112 12.2046 8.95779 12.4726 8.99312C14.2766 9.26312 14.6546 10.0985 14.6546 10.7511C14.6546 11.2371 14.4446 11.8951 13.4426 12.2745C13.3846 12.2965 13.3246 12.3071 13.2653 12.3071Z"
                    fill="black"
                  />
                </svg>
              ),
            },
            {
              key: "4",
              label: "Descargar",
              icon: (
                <svg width="16" height="16" viewBox="4 4 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 15.9853L15.182 12.8033L14.1213 11.7427L12.75 13.114L12.75 5.25L11.25 5.25L11.25 13.114L9.8787 11.7427L8.81804 12.8033L12 15.9853ZM12 13.864L12 13.864L12.0001 13.864L12 13.864Z" fill="black"/>
                <path d="M18 17.25L18 18.75L6 18.75L6 17.25L18 17.25Z" fill="black"/>
                </svg>
              ),
            },
            {
              key: "5",
              label: "Eliminar",
              icon: (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.16359 14.6666C7.26025 14.6666 6.37959 14.6566 5.50825 14.6386C4.39358 14.6166 3.62225 13.8939 3.49625 12.7526C3.28625 10.8593 2.92692 6.3966 2.92358 6.35194C2.90092 6.0766 3.10625 5.83527 3.38159 5.81327C3.65292 5.80594 3.89825 5.9966 3.92025 6.27127C3.92359 6.3166 4.28225 10.7639 4.49025 12.6426C4.56159 13.2913 4.91158 13.6259 5.52892 13.6386C7.19559 13.6739 8.89625 13.6759 10.7296 13.6426C11.3856 13.6299 11.7403 13.3019 11.8136 12.6379C12.0203 10.7753 12.3803 6.3166 12.3843 6.27127C12.4063 5.9966 12.6496 5.8046 12.9223 5.81327C13.1976 5.83594 13.4029 6.0766 13.3809 6.35194C13.3769 6.39727 13.0156 10.8713 12.8076 12.7479C12.6783 13.9126 11.9089 14.6213 10.7476 14.6426C9.85892 14.6579 9.00159 14.6666 8.16359 14.6666Z"
                    fill="black"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.8053 4.65942H2.5C2.224 4.65942 2 4.43542 2 4.15942C2 3.88342 2.224 3.65942 2.5 3.65942H13.8053C14.0813 3.65942 14.3053 3.88342 14.3053 4.15942C14.3053 4.43542 14.0813 4.65942 13.8053 4.65942Z"
                    fill="black"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.6264 4.6595C10.8677 4.6595 10.2091 4.11883 10.0597 3.37483L9.89774 2.56416C9.86374 2.44083 9.72307 2.3335 9.56307 2.3335H6.74107C6.58107 2.3335 6.4404 2.44083 6.39973 2.59483L6.2444 3.37483C6.09573 4.11883 5.4364 4.6595 4.67773 4.6595C4.40173 4.6595 4.17773 4.4355 4.17773 4.1595C4.17773 3.8835 4.40173 3.6595 4.67773 3.6595C4.96173 3.6595 5.2084 3.45683 5.2644 3.17816L5.4264 2.3675C5.59107 1.74616 6.12907 1.3335 6.74107 1.3335H9.56307C10.1751 1.3335 10.7131 1.74616 10.8711 2.3375L11.0404 3.17816C11.0957 3.45683 11.3424 3.6595 11.6264 3.6595C11.9024 3.6595 12.1264 3.8835 12.1264 4.1595C12.1264 4.4355 11.9024 4.6595 11.6264 4.6595Z"
                    fill="black"
                  />
                </svg>
              ),
            }
          ]);
          break;
        case "Customer":
          fetchData({
            filter: {
              groups: { eq: userContext.attributes['custom:group'] },
              is_deleted: { eq: false },
              ...filter,
            },
            limit: 2000,
          });
          setItemsMenu([
            {
              key: "1",
              label: "Ver / Editar",
              icon: (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 18 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="Fill 1"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.8334 14.6265H8.99805C8.72205 14.6265 8.49805 14.4025 8.49805 14.1265C8.49805 13.8505 8.72205 13.6265 8.99805 13.6265H13.8334C14.1094 13.6265 14.3334 13.8505 14.3334 14.1265C14.3334 14.4025 14.1094 14.6265 13.8334 14.6265Z"
                    fill="black"
                  />
                  <path
                    id="Clip 4"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.33398 2H12.7877V14.6264H1.33398V2Z"
                    fill="white"
                  />
                  <path
                    id="Fill 3"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.74083 3.34443L2.46417 11.1944C2.35017 11.3371 2.30817 11.5211 2.35017 11.6978L2.80417 13.6211L4.83017 13.5958C5.02283 13.5938 5.20083 13.5078 5.31883 13.3611C7.4635 10.6778 11.5522 5.56176 11.6682 5.41176C11.7775 5.23443 11.8202 4.98376 11.7628 4.74243C11.7042 4.4951 11.5502 4.2851 11.3282 4.1511C11.2808 4.11843 10.1575 3.24643 10.1228 3.2191C9.70017 2.88043 9.0835 2.9391 8.74083 3.34443ZM2.4095 14.6264C2.17817 14.6264 1.97683 14.4678 1.92283 14.2418L1.37683 11.9278C1.26417 11.4484 1.37617 10.9538 1.6835 10.5698L7.9635 2.7151C7.96617 2.71243 7.96817 2.7091 7.97083 2.70643C8.6595 1.8831 9.90483 1.76176 10.7448 2.43576C10.7782 2.46176 11.8935 3.32843 11.8935 3.32843C12.2988 3.56976 12.6155 4.0011 12.7355 4.51176C12.8548 5.0171 12.7682 5.53843 12.4902 5.97909C12.4695 6.01176 12.4515 6.03976 6.0995 13.9864C5.7935 14.3678 5.33483 14.5898 4.84217 14.5958L2.41617 14.6264H2.4095Z"
                    fill="black"
                  />
                  <path
                    id="Fill 6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.8163 7.78983C10.7096 7.78983 10.6029 7.75583 10.5116 7.6865L6.87693 4.8945C6.65826 4.7265 6.61693 4.41316 6.78493 4.19316C6.9536 3.9745 7.26693 3.93383 7.48626 4.10183L11.1216 6.89316C11.3403 7.06116 11.3816 7.37516 11.2129 7.5945C11.1149 7.7225 10.9663 7.78983 10.8163 7.78983Z"
                    fill="black"
                  />
                </svg>
              ),
            },
            {
              key: "3",
              label: "Permiso",
              icon: (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="Clip 2"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.33398 9.30469H11.4565V14.0378H1.33398V9.30469Z"
                    fill="white"
                  />
                  <path
                    id="Fill 1"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.39532 10.3047C4.54265 10.3047 2.33398 10.5427 2.33398 11.6794C2.33398 12.5807 3.70065 13.038 6.39532 13.038C9.08998 13.038 10.4567 12.576 10.4567 11.666C10.4567 10.7627 9.08998 10.3047 6.39532 10.3047ZM6.39532 14.038C5.01132 14.038 1.33398 14.038 1.33398 11.6794C1.33398 9.30469 5.14332 9.30469 6.39532 9.30469C8.56798 9.30469 11.4567 9.54935 11.4567 11.666C11.4567 14.038 7.64732 14.038 6.39532 14.038Z"
                    fill="black"
                  />
                  <path
                    id="Fill 4"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.3947 2.33325C5.05804 2.33325 3.9707 3.42059 3.9707 4.75659C3.9707 6.09259 5.05804 7.17992 6.3947 7.17992H6.41537C7.05804 7.17725 7.66337 6.92458 8.11737 6.46658C8.57204 6.00992 8.8207 5.40259 8.81804 4.75858C8.81804 3.42059 7.7307 2.33325 6.3947 2.33325ZM6.3947 8.17992C4.5067 8.17992 2.9707 6.64392 2.9707 4.75659C2.9707 2.86925 4.5067 1.33325 6.3947 1.33325C8.28204 1.33325 9.81804 2.86925 9.81804 4.75659C9.82204 5.66592 9.46937 6.52458 8.8267 7.17125C8.18537 7.81858 7.3287 8.17659 6.41737 8.17992H6.3947Z"
                    fill="black"
                  />
                  <path
                    id="Fill 6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.9897 7.42118C10.7444 7.42118 10.5304 7.24051 10.4951 6.99051C10.4571 6.71718 10.6471 6.46384 10.9204 6.42584C11.7524 6.30918 12.3804 5.58718 12.3817 4.74584C12.3817 3.90984 11.7831 3.20451 10.9597 3.06984C10.6871 3.02451 10.5024 2.76784 10.5471 2.49518C10.5917 2.22251 10.8497 2.03918 11.1211 2.08251C12.4311 2.29718 13.3817 3.41784 13.3817 4.74651C13.3791 6.08318 12.3811 7.23118 11.0597 7.41651C11.0364 7.41984 11.0131 7.42118 10.9897 7.42118Z"
                    fill="black"
                  />
                  <path
                    id="Clip 9"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.8984 8.98877H14.6543V12.3071H11.8984V8.98877Z"
                    fill="white"
                  />
                  <path
                    id="Fill 8"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.2653 12.3071C13.0633 12.3071 12.8733 12.1838 12.798 11.9845C12.7 11.7265 12.83 11.4371 13.088 11.3398C13.6546 11.1251 13.6546 10.8631 13.6546 10.7511C13.6546 10.3731 13.2073 10.1145 12.3253 9.98312C12.052 9.94179 11.8633 9.68712 11.904 9.41446C11.9453 9.14112 12.2046 8.95779 12.4726 8.99312C14.2766 9.26312 14.6546 10.0985 14.6546 10.7511C14.6546 11.2371 14.4446 11.8951 13.4426 12.2745C13.3846 12.2965 13.3246 12.3071 13.2653 12.3071Z"
                    fill="black"
                  />
                </svg>
              ),
            },
            {
              key: "4",
              label: "Descargar",
              icon: (
                <svg width="16" height="16" viewBox="4 4 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 15.9853L15.182 12.8033L14.1213 11.7427L12.75 13.114L12.75 5.25L11.25 5.25L11.25 13.114L9.8787 11.7427L8.81804 12.8033L12 15.9853ZM12 13.864L12 13.864L12.0001 13.864L12 13.864Z" fill="black"/>
                <path d="M18 17.25L18 18.75L6 18.75L6 17.25L18 17.25Z" fill="black"/>
                </svg>
              ),
            },
            {
              key: "5",
              label: "Eliminar",
              icon: (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.16359 14.6666C7.26025 14.6666 6.37959 14.6566 5.50825 14.6386C4.39358 14.6166 3.62225 13.8939 3.49625 12.7526C3.28625 10.8593 2.92692 6.3966 2.92358 6.35194C2.90092 6.0766 3.10625 5.83527 3.38159 5.81327C3.65292 5.80594 3.89825 5.9966 3.92025 6.27127C3.92359 6.3166 4.28225 10.7639 4.49025 12.6426C4.56159 13.2913 4.91158 13.6259 5.52892 13.6386C7.19559 13.6739 8.89625 13.6759 10.7296 13.6426C11.3856 13.6299 11.7403 13.3019 11.8136 12.6379C12.0203 10.7753 12.3803 6.3166 12.3843 6.27127C12.4063 5.9966 12.6496 5.8046 12.9223 5.81327C13.1976 5.83594 13.4029 6.0766 13.3809 6.35194C13.3769 6.39727 13.0156 10.8713 12.8076 12.7479C12.6783 13.9126 11.9089 14.6213 10.7476 14.6426C9.85892 14.6579 9.00159 14.6666 8.16359 14.6666Z"
                    fill="black"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.8053 4.65942H2.5C2.224 4.65942 2 4.43542 2 4.15942C2 3.88342 2.224 3.65942 2.5 3.65942H13.8053C14.0813 3.65942 14.3053 3.88342 14.3053 4.15942C14.3053 4.43542 14.0813 4.65942 13.8053 4.65942Z"
                    fill="black"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.6264 4.6595C10.8677 4.6595 10.2091 4.11883 10.0597 3.37483L9.89774 2.56416C9.86374 2.44083 9.72307 2.3335 9.56307 2.3335H6.74107C6.58107 2.3335 6.4404 2.44083 6.39973 2.59483L6.2444 3.37483C6.09573 4.11883 5.4364 4.6595 4.67773 4.6595C4.40173 4.6595 4.17773 4.4355 4.17773 4.1595C4.17773 3.8835 4.40173 3.6595 4.67773 3.6595C4.96173 3.6595 5.2084 3.45683 5.2644 3.17816L5.4264 2.3675C5.59107 1.74616 6.12907 1.3335 6.74107 1.3335H9.56307C10.1751 1.3335 10.7131 1.74616 10.8711 2.3375L11.0404 3.17816C11.0957 3.45683 11.3424 3.6595 11.6264 3.6595C11.9024 3.6595 12.1264 3.8835 12.1264 4.1595C12.1264 4.4355 11.9024 4.6595 11.6264 4.6595Z"
                    fill="black"
                  />
                </svg>
              ),
            },
          ]);
          break;
        default: break;
      }
    }
  }, [rolUser, searchValue, isModalOpenUpdate, isModalOpenDelete]);

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const updateAudience = async (input) => {
    try {
      await API.graphql({
        query: `
          mutation UpdateAudience(
            $input: UpdateAudienceInput!
          ) {
            updateAudience(input: $input) {
              id
            }
        }`,
        variables: {
          input: input,
        },
      })
      setIsModalOpenUpdate(false)
      setIsModalOpenDelete(false)
    } catch (err) {
      //console.log("Error actualizando", err);
    }
  }

  const createLog = async (values) => {
    const usuario = await API.graphql({
     query: `mutation CreateMetrics( $input: CreateMetricsInput!){
                createMetrics(input:$input) {
                    id
                }
            }`,
        variables: {
            input : values
          },
        });
  };

  
  const DownloadAudience = async (values) => {
    try {
      const response = await API.graphql({
        query: `
          query MyQuery($input: downloadAudienceInput!) {
            downloadAudience(input: $input)
          }
        `,
        variables: {
          input: values
        }
      });
      if (response.data && response.data.downloadAudience) {
        window.open(response.data.downloadAudience, '_blank');
      } else {
        console.error('No se recibió ningún enlace de descarga.');
      }
    } catch (error) {
      console.error('Ocurrió un error al realizar la solicitud:', error);
    }
  };

  const handleCancel = () => {
    setIsModalOpenUpdate(false);
    setIsModalOpenDelete(false);
    setIsModalExport(false);
    setisModalListExport(false);
    setIsModalDownload(false);
  };

  const searchinexports = async ( variables ) => {
    const result = await API.graphql({
      query: `
        query ListAudienceExports($filter: ModelAudienceExportFilterInput) {
          listAudienceExports(filter: $filter){
            items{
              id
              export{
                name
                id
              }
              audience{
                id
              }
              }
            }
      }`,
      variables: variables
    })
    setRelationExport(result.data.listAudienceExports.items);
  }

  useEffect(() =>{
    if(relationexport){
    if (relationexport.length != 0){
      warning();
    }else{
      setIsModalOpenDelete(true);
    }}
  }, [relationexport]);

  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: "No es posible eliminar la audiencia, actualmente se encuentra dentro de una exportación.",
    });
  };

  useEffect(() => {
    if (data) {
      const onClickMenuAcciones = (record) => (e) => {
        switch (e.key) {
          case "1":
            navigate(`/audience/${record.id}`);
            break;
          case "2":
            setIsModalExport(true);
            setIdAudience(record.id);
            break;
          case "3":
            setIsModalOpenUpdate(true);
            setIdAudience(record.id);
            setPermission(record.isPublic);
            break;
          case "4":
            DownloadAudience({id:record.id,mode: "Audience"})
            break;
          case "5":
            searchinexports({
              filter:{
                audienceID:{
                  eq: record.id
                }
              }
            });
            setIdAudience(record.id);
            break;
          default:
        }
      };
      const menu = (record) => {
        return <Menu onClick={onClickMenuAcciones(record)} items={itemsMenu} />;
      };
      if (typeGrid == "list") {
        const columns = [
          {
            title: (
              <>
                <Row>
                  <Col span="18" style={{ top: "15px" }}>
                    Nombre
                  </Col>
                  <Col span="4">
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444"
                        stroke="#7084B1"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Col>
                </Row>
              </>
            ),
            dataIndex: "name",
            key: "id",
            ...getColumnSearchProps("name"),
            sorter: (a, b) => {
              return a.name.localeCompare(b.name);
            },
            showSorterTooltip: false,
          },
          {
            title: (
              <>
                <Row>
                  <Col span="18" style={{ top: "15px" }}>
                    Lista aplicada
                  </Col>
                  <Col span="4">
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444"
                        stroke="#7084B1"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Col>
                </Row>
              </>
            ),
            dataIndex: "clm",
            render: (text) => (text ? text.name : 'NA'),
            sorter: (a, b) => {
              if (a.clm && b.clm) {
                if (a.clm.name < b.clm.name) return -1;
                if (a.clm.name > b.clm.name) return 1;
                return 0;
              }
            },
            showSorterTooltip: false,
          },
          {
            title: (
              <>
                <Row>
                  <Col className="ColHeader2" span="18">
                    Fecha de
                    <br />
                    modificación
                  </Col>
                  <Col span="4" style={{ top: "8px" }}>
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444"
                        stroke="#7084B1"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Col>
                </Row>
              </>
            ),
            defaultSortOrder: 'descend',
            dataIndex: "updatedAt",
            sorter: {
              compare: (a, b) =>
                Date.parse(a.updatedAt) - Date.parse(b.updatedAt),
            },
            showSorterTooltip: false,
            render: (text) => <span>{text.substring(0, 10)}</span>,
          },
          {
            title: (
              <>
                <Row>
                  <Col span="18" style={{ top: "15px" }}>
                    Creado por
                  </Col>
                  <Col span="4">
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444"
                        stroke="#7084B1"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Col>
                </Row>
              </>
            ),
            dataIndex: "user",
            render: (text) => <>{text.username}</>,
            sorter: (a,b) => {
              if (a.user.username < b.user.username) return -1;
              if (a.user.username > b.user.username) return 1;
              return 0;
            },
            showSorterTooltip: false,
          },
          {
            title: (
              <>
                <Row>
                  <Col span="18" style={{ top: "15px" }}>
                    Grupo del usuario
                  </Col>
                  <Col span="4">
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444"
                        stroke="#7084B1"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Col>
                </Row>
              </>
            ),
            dataIndex: "userGroup",
            render: (text, record) => {
              if (record.user && record.user.groups && record.user.groups.items.length > 0) {
                return record.user.groups.items[0].group.name;
              } else {
                return 'N/A';
              }
            },
            sorter: (a, b) => {
              const groupNameA = a.user && a.user.groups && a.user.groups.items.length > 0 ? a.user.groups.items[0].group.name : '';
              const groupNameB = b.user && b.user.groups && b.user.groups.items.length > 0 ? b.user.groups.items[0].group.name : '';
              return groupNameA.localeCompare(groupNameB);
            },
            showSorterTooltip: false,
          },
          {
            title: (
              <>
                <Row>
                  <Col span="18" style={{ top: "15px" }}>
                    Permisos
                  </Col>
                  <Col span="4">
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444"
                        stroke="#7084B1"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Col>
                </Row>
              </>
            ),
            dataIndex: "isPublic",
            render: (text) => (text ? "Publico" : "No publico"),
            sorter: {
              compare: (a, b) => a.isPublic - b.isPublic,
              multiple: 2,
            },
            showSorterTooltip: false,
          },
          {
            title: (
              <>
                <Row>
                  <Col className="ColHeader2" span="18">
                    Población
                    <br />
                    direccionable
                  </Col>
                  <Col span="4" style={{ top: "8px" }}>
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444"
                        stroke="#7084B1"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Col>
                </Row>
              </>
            ),
            dataIndex: "count",
            sorter: {
              compare: (a, b) => a.count - b.count,
            },
            render: (int) => {
              if (int > 60) {
                return int.toLocaleString("en-US", {
                  notation: "compact",
                  compactDisplay: "short",
                })
              } if (int < 60) {
                return "<60"
              }
            },
            showSorterTooltip: false,
          },
          {
            title: "Acciones",
            dataIndex: "permisos",
            render: (_, record) => (
              <Dropdown overlay={() => menu(record)}>
                <a className="optionsButton">
                  <svg
                    width="3"
                    height="10"
                    viewBox="0 0 3 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="1.5" cy="1" r="1" fill="#181D2E" />
                    <circle cx="1.5" cy="5" r="1" fill="#181D2E" />
                    <circle cx="1.5" cy="9" r="1" fill="#181D2E" />
                  </svg>
                </a>
              </Dropdown>
            ),
          },
        ];
        setGrid(
          <Table
            size="small"
            columns={columns}
            dataSource={data}
            style={{ width: "100%" }}
            loading={showLoadingData}
          />
        );
      } else {
        const indexOfLastCard = currentPage * cardsPerPage;
        const indexOfFirstCard = indexOfLastCard - cardsPerPage;
        const currentCards = data.slice(indexOfFirstCard, indexOfLastCard);
        setGrid(
          <>{currentCards.map((item,index) => (
              <Col className="ColCard" span={6}>        
              <Card
                  className="cardResumen"
                  title={item.name}
                  key={item.id}
                  loading={showLoadingData}
                  extra={
                    <Dropdown overlay={() => menu(item)}>
                      <a className="optionsButton">
                        <svg
                          width="3"
                          height="10"
                          viewBox="0 0 3 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="1.5" cy="1" r="1" fill="#181D2E" />
                          <circle cx="1.5" cy="5" r="1" fill="#181D2E" />
                          <circle cx="1.5" cy="9" r="1" fill="#181D2E" />
                        </svg>
                      </a>
                    </Dropdown>
                  }
                >       
                  <div style={{ margin: -5, height: 190 }}>
                    <Row gutter={2} style={{ marginBottom: 5 }}>
                      <Col span={17}>
                        <Space direction="vertical" size={0}>
                          <Text type="secondary">Creador por</Text>
                          <Text>{item.user.username}</Text>
                        </Space>
                      </Col>
                      <Col span={7} align="end">
                        <Text type="secondary">Compartir</Text>
                        <Switch
                          checked={item.isPublic}
                          onChange={() => (
                            setIsModalOpenUpdate(true),
                            setIdAudience(item.id),
                            setPermission(item.isPublic)
                          )}
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: 5 }}>
                      <Col className="ColComportamientosCard">
                        <div>{item.clm ? `BE: ${item.clm.name}` : null}</div>
                        <Text type="secondary">Comportamientos</Text>
                        <br />
                        <div
                          className="ComportamientosCard"
                          direction="vertical"
                          size={0}
                          style={item.clm ? {height: 45} : {height: 65}}
                        >
                          {data ? mapFilters(item.filters) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row justify="middle">
                      <Col>
                        <Space direction="vertical" size={5}>
                          <Text type="secondary">Población</Text>
                          <Title level={4} style={{ color: "#0D33B9" }}>
                          {item.count?item.count.toLocaleString("en-US", {
                              notation: "compact",
                              compactDisplay: "short",
                            }):"<60"}
                          </Title>
                        </Space>
                      </Col>
                    </Row>
                  </div>
                </Card> </Col>))}
               
                  <br/> 

                <Pagination
                defaultCurrent={1}
                current={currentPage}
                total={data.length}
                pageSize={cardsPerPage}
                onChange={handlePageChange}
                showSizeChanger={false}
                style={{ marginTop: '20px' }}
                />

            </>
        );
      }
    }
  }, [data, typeGrid, showLoadingData, currentPage]);

  const mapFilters = (filters) => {
    return filters.map((e) => {
      switch (e.type_filter) {
        case 1:
          return (
            <>
              <span className="SubmenuBoldGA">Genero:</span> {e.value_filter};{" "}
            </>
          );
        case 2:
          return (
            <>
              <span className="SubmenuBoldGA">Edad:</span> {e.value_filter};{" "}
            </>
          );
        case 3:
          return (
            <>
              <span className="SubmenuBoldGA">Ingreso:</span> {e.value_filter};{" "}
            </>
          );
        case 4:
          return (
            <>
              <span className="SubmenuBoldGA">Ubicacion:</span> {e.value_filter};{" "}
            </>
          );
        case 5:
          return (
            <>
              <span className="SubmenuBoldGA">Comportamiento:</span> {e.value_filter};{" "}
            </>
          );
        case 6:
          return (
            <>
              <span className="SubmenuBoldGA">Marca:</span> {e.value_filter};{" "}
            </>
          );
        case 7:
          return (
            <>
              <span className="SubmenuBoldGA">OS:</span> {e.value_filter};{" "}
            </>
          );
        case 8:
          return (
            <>
              <span className="SubmenuBoldGA">Tipo:</span> {e.value_filter};{" "}
            </>
          );
        case 9:
          return (
            <>
              <span className="SubmenuBoldGA">Fecha:</span> {e.value_filter};{" "}
            </>
          );
        case 10:
          return (
            <>
              <span className="SubmenuBoldGA">Hora:</span> {e.value_filter};{" "}
            </>
          );
        default:
          return null;
      }
    });
  };

  const updateExport = async (input) => {
    try {
      const result = await API.graphql({
        query: `
          mutation CreateAudienceExport(
            $input: CreateAudienceExportInput!
            $condition: ModelAudienceExportConditionInput
          ) {
            createAudienceExport(input: $input, condition: $condition) {
              id
            }
        }`,
        variables: {
          input: input,
        },
      })
      navigate(`/exports/`);
    } catch (err) {
      //console.log("Error actualizando", err);
    }
  };
  const getData = async (filter) => {
    const allTodos = await API.graphql({
      query: `
      query ListExports(
        $filter: ModelExportFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listExports(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            name
            status
            dateEnd
            dateStart
            createdAt
            count
            description
            error
            user {
              name
              username
            }
            audience {
              items {
                audience {
                  name
                  isPublic
                  id
                  count
                  user {
                    name
                    username
                  }
                  filters {
                    type_filter
                    value_filter
                  }
                }
              }
            }
          }}}`,
        variables:{
          filter: {
            dateEnd :{
              gt: formattedDate
            }
          }
        }
    });
    let dataAudienceExports = []
    try {
    dataAudienceExports = await API.graphql({
      query: `
      query ListAudienceExports(
        $filter: ModelAudienceExportFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listAudienceExports(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            audienceID
            export {
              id
            }
    }}}`,
        variables:{
          filter: {
            //audienceID: {eq: "596d5c29-b352-4e1f-aa74-cd29bd973c86"}
            audienceID: {eq: idAudience}
          }
        }
    });
    } catch (err) {
      //console.log("Error actualizando", err);
    }
    let auxDataExports = []
    if (dataAudienceExports.data.listAudienceExports.items.length != 0) {
      const auxAudienceExports = dataAudienceExports.data.listAudienceExports.items
      const auxListExports = allTodos.data.listExports.items
      auxListExports.forEach(item => auxAudienceExports.map(e => item.id !== e.export.id ? auxDataExports.push(item) : null))
    }
    else {
      auxDataExports = allTodos.data.listExports.items
    }
    auxDataExports.map((item) => item.key = item.id)
    setDataExport(auxDataExports);
    setShowLoadingData(false)
  };

  useEffect(() => {
    if (rolUser){
      setShowLoadingData(true)
      getData()
    }
}, [isModalListExport])

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    setSeledRowsExport(selectedRows)
    setSelectedRowKeys(selectedRowKeys[0])
  },
  getCheckboxProps: (record) => ({
    disabled: record.id === 'Disabled User',
    // Column configuration not to be checked
    name: record.id,
  }),
};


async function DeleteAudience(){
  await updateAudience(({
    id: idAudience,
    is_deleted: true,
  }));
  await createLog({
    action:"Eliminación de audiencia",
    id: uuidv4(),
    user:userContext.username,
    view:"Listado de audiencias",
  });
}

  return (
    <div className="ListAudiencesComponent">
      <Modal
        clasName="ModalGA"
        title="Atención"
        open={isModalOpenDelete}
        onCancel={() => setIsModalOpenDelete(false)}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={DeleteAudience}
          >
            Eliminar
          </Button>,
        ]}
      >
        <p>¿Estás seguro que deseas eliminar está audiencia?</p>
      </Modal>
      <Modal
        clasName="ModalGA"
        title="Atención"
        open={isModalOpenUpdate}
        onOk={() => updateAudience(({
          id: idAudience,
          isPublic: !permission,
        }))}
        onCancel={handleCancel}
      >
        <p>¿Estás seguro que deseas cambiar los permisos de la audiencia?</p>
      </Modal>
      <Modal
        clasName="ModalGA"
        title="Atención"
        open={isModalExport}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={() => setisModalListExport(true)}>
            Creada
          </Button>,
          
          <Button
            key="submit"
            type="primary"
            onClick={() => navigate(`/export/${idAudience}`)}
          >
            Nueva
          </Button>,
        ]}
      >
        <p>¿Qué tipo de exportación desea?</p>
      </Modal>
      <Modal
        clasName="ModalGA"
        title="Elige el bloque de Audiencias a decargar"
        open={isModalDownload}
        onCancel={handleCancel}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => DownloadAudience({id:userContext.attributes.sub,mode: "Owner"})}
          >
            Personal
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => DownloadAudience({id:userContext.attributes.sub,mode: "Group"})}
          >
            Grupo
          </Button>,
        ]}
      >
        <p>¿Qué bloque de audiencias desea descargar?</p>
      </Modal>
      <Modal
        clasName="ModalGA"
        title="Lista de exportaciones"
        open={isModalListExport}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => updateExport(({
              audienceID: idAudience,
              exportID: selectedRowKeys,
            }))
            }
          >
            Guardar
          </Button>,
        ]}
        width={800}
      >
        <> 
        <Spin spinning={showLoadingData}>
        <Table
          className="tableListExport"
          style={{selections:true}} 
          columns={[
            {
              title: (
                <>
                  <Row>
                    <Col className="ColHeader2" span="18" style={{ top: "5px" }}>
                      Nombre de la exportación
                    </Col>
                    <Col span="4" style={{ top: "8px" }}>
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444"
                          stroke="#7084B1"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Col>
                  </Row>
                </>
              ),
              dataIndex: "name",
              key: "name",
              sorter: (a, b) => {
                return a.name.localeCompare(b.name);
              },
              showSorterTooltip: false,
          ...getColumnSearchProps("name")
            },
            {
              title: (
                <>
                  <Row>
                    <Col span="18" style={{ top: "5px" }}>
                      Creado por
                    </Col>
                    <Col span="4">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444"
                          stroke="#7084B1"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Col>
                  </Row>
                </>
              ),
              dataIndex: "user",
              
              render: (text) => <>{text.username}</>,
              sorter: (a,b) => {
                if (a.user.username < b.user.username) return -1;
                if (a.user.username > b.user.username) return 1;
                return 0;
              },
              showSorterTooltip: false,
              //cambio en el render para visualizar nombre de usuario
            },
            {
              title: (
                <>
                  <Row>
                    <Col span="18" style={{ top: "5px" }}>
                      Fecha de inicio
                    </Col>
                    <Col span="4">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444"
                          stroke="#7084B1"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Col>
                  </Row>
                </>
              ),
              key: "dateStart",
              dataIndex: "dateStart",
              sorter: {
                compare: (a, b) => Date.parse(a.dateStart) - Date.parse(b.dateStart),
              },
              showSorterTooltip: false,
            },
            {
              title: (
                <>
                  <Row>
                    <Col span="18" style={{ top: "5px" }}>
                      Fecha de finalización
                    </Col>
                    <Col span="4">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444"
                          stroke="#7084B1"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Col>
                  </Row>
                </>
              ),
              dataIndex: "dateEnd",
              key: "dateEnd",
              sorter: {
                compare: (a, b) => Date.parse(a.dateEnd) - Date.parse(b.dateEnd),
              },
              showSorterTooltip: false,
            },
          ]}
          dataSource={dataExport}
          size="small"
          rowSelection={{
            type: 'radio',
            ...rowSelection,
          }}
          loading={showLoadingData}
        />
    </Spin>
        </>
      </Modal>
      <Row className="MenuListAudience" align="middle">
        <Col
          span={4}
          className="TituloBoldGA Col1MenuExports"
          style={{ textTranform: "uppercase" }}
        >
          Audiencias guardadadas
        </Col>
        <Col span={6} offset ={3}>
          <Space direction="vertical">
            <Search
              className="SubtituloRegularGA"
              placeholder="Buscar audiencia"
              allowClear
              onSearch={onSearch}
            />
          </Space>
        </Col>
        <ResponsiveVisibility maxWidth={1031}>
          <Col span={2}>
              <Button
                className="SubtituloRegularGA Button1"
                type="primary"
                onClick={() => {
                  navigate("/audience");
                }}
                >
                Nueva
              </Button>
          </Col>
        </ResponsiveVisibility>
        <Col span={2}>
          <Button
            className="SubtituloRegularGA Button1"
            type="primary"
            onClick={() => {
              setIsModalDownload(true)
            }}
          >
            Descargar audiencias
          </Button>
        </Col>
        <Col span={4} offset ={3}>
        <ResponsiveVisibility maxWidth={1200}>
          <Row className="RowVisualizacion">
            <Col span={14}>
              <span className="SubtituloRegularGA">Visualización</span>
            </Col>
            <Col span={5}>
              <Button onClick={() => setTypeGrid("card")}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 18C17 17.4477 17.4477 17 18 17C18.5523 17 19 17.4477 19 18C19 18.5523 18.5523 19 18 19C17.4477 19 17 18.5523 17 18Z"
                    stroke={typeGrid === "card" ? "#0D33B9" : "#7084B1"}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18Z"
                    stroke={typeGrid === "card" ? "#0D33B9" : "#7084B1"}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5 18C5 17.4477 5.44772 17 6 17C6.55228 17 7 17.4477 7 18C7 18.5523 6.55228 19 6 19C5.44772 19 5 18.5523 5 18Z"
                    stroke={typeGrid === "card" ? "#0D33B9" : "#7084B1"}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17 12C17 11.4477 17.4477 11 18 11C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13C17.4477 13 17 12.5523 17 12Z"
                    stroke={typeGrid === "card" ? "#0D33B9" : "#7084B1"}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12Z"
                    stroke={typeGrid === "card" ? "#0D33B9" : "#7084B1"}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5 12C5 11.4477 5.44772 11 6 11C6.55228 11 7 11.4477 7 12C7 12.5523 6.55228 13 6 13C5.44772 13 5 12.5523 5 12Z"
                    stroke={typeGrid === "card" ? "#0D33B9" : "#7084B1"}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17 6C17 5.44772 17.4477 5 18 5C18.5523 5 19 5.44772 19 6C19 6.55228 18.5523 7 18 7C17.4477 7 17 6.55228 17 6Z"
                    stroke={typeGrid === "card" ? "#0D33B9" : "#7084B1"}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11 6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6C13 6.55228 12.5523 7 12 7C11.4477 7 11 6.55228 11 6Z"
                    stroke={typeGrid === "card" ? "#0D33B9" : "#7084B1"}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5 6C5 5.44772 5.44772 5 6 5C6.55228 5 7 5.44772 7 6C7 6.55228 6.55228 7 6 7C5.44772 7 5 6.55228 5 6Z"
                    stroke={typeGrid === "card" ? "#0D33B9" : "#7084B1"}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Button>
            </Col>
            <Col span={5}>
              <Button onClick={() => setTypeGrid("list")}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 17H21"
                    stroke={typeGrid !== "card" ? "#0D33B9" : "#7084B1"}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3 12H21"
                    stroke={typeGrid !== "card" ? "#0D33B9" : "#7084B1"}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3 7H21"
                    stroke={typeGrid !== "card" ? "#0D33B9" : "#7084B1"}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Button>
            </Col>
          </Row>
        </ResponsiveVisibility>
        </Col>
      </Row>
      {data ? ( <> {contextHolder}
        {grid && <Row>{grid}</Row>} </>
      ) : (
        <div>
          {showLoadingData ? (
            <h3>Cargando Audiencias.</h3>
          ) : (
            <h3>No tiene audiencias creadas.</h3>
          )}
        </div>
      )}
    </div>
  );
};
ListAudience.propTypes = {};
export default ListAudience;
