import { useState, useEffect, useContext } from 'react';
import {
  Table,
  Tooltip,
  Tag,
  Button,
  Row,
  Col,
  Space,
  Input,
  Empty,
  Modal,
  Upload,
  Menu,
  Dropdown,
  Spin,
  message
} from 'antd';
import moment from "moment";
import { API, Storage } from 'aws-amplify';
import { UserContext } from "../../../../GestorContext";

import './DataBaseExternal.css'
import ResponsiveVisibility from '../../../common/ResponsiveVisibility';


const { Search } = Input;

const DataBaseExternal = (
  {
    dataBaseExternal,
    setDataBaseExternal,
    setDBExternalName
  }
) => {

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState()
  const [file, setFile] = useState()
  const [nameData, setNameData] = useState()
  const [itemsMenu, setItemsMenu] = useState();
  const [idBaseExterna, setIdBaseExterna] = useState();
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const [loadingAddExternalBase, setLoadingAddExternalBase] = useState(false)

  const userContext = useContext(UserContext);

  const deleteBaseExterna = async (input) => {
    try {
      await API.graphql({
        query: `
          mutation MyQuery($input: DeleteCLMInput!) {
            deleteCLM(input: $input) {
              id
            }
          }`,
        variables: {
          input: input,
        },
      })
      setIsModalOpenDelete(false)
    } catch (err) {
      //console.log("Error actualizando", err);
    }
  }
  const onClickMenuAcciones = (record) => (e) => {
    switch (e.key) {
      case "1":
        setIsModalOpenDelete(true);
        setIdBaseExterna(record.id);
        break;
      case "2":
          setDataBaseExternal(null)
          setDBExternalName(null)
          break;
      default:
    }
  };
  const menu = (record) => {
    return <Menu onClick={onClickMenuAcciones(record)} items={itemsMenu} />;
  };
  const columns = [
    {
      title: (
        <ResponsiveVisibility maxWidth={1050}>
        <Row>
          <Col span="18" style={{ top: '15px' }}>
            Nombre
          </Col>
          <Col span="4">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444" stroke="#7084B1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Col>
        </Row>
        </ResponsiveVisibility>
      ),
      dataIndex: 'name',
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <ResponsiveVisibility maxWidth={1050}>
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
        </ResponsiveVisibility>
      ),
      //render: (text) => <a>{text}</a>,
    },
    {
      title: <>
      <ResponsiveVisibility maxWidth={1050}>
        <Row>
          <Col span="18" style={{ top: '15px' }}>
            Estado
          </Col>
          <Col span="4">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444" stroke="#7084B1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Col>
        </Row>
      </ResponsiveVisibility>

      </>,
      dataIndex: 'estatus',
      render: (_, { status }) => {
        let color
        let statusDescription
        switch (status) {
          case 'FAILED':
            color = 'var(--support-error)'
            statusDescription = 'Error'
            break
          case 'FINISHED':
            color = 'var(--support-correct)'
            statusDescription = 'Listo'
            break
          case 'STARTED' || 'RUNNING':
            color = 'var(--support-pend)'
            statusDescription = 'En proceso'
            break
          case 'PENDING':
            color = 'var(--support-correct)'
            statusDescription = 'Cargada'
            break
          default:
            color = 'var(--support-pend)'
            statusDescription = 'No disponibe'
        }
        return (
          <ResponsiveVisibility maxWidth={1050}>
          <Tag className='tagExports' color={color}>
            {statusDescription}
            </Tag>
          </ResponsiveVisibility>
        );


      },
    },
    {
      title: <>
        <Row>
          <Col className='ColHeader2' span="18">
            Fecha de incio
          </Col>
          <Col span="4" style={{ top: '8px' }}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444" stroke="#7084B1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Col>
        </Row>
      </>,
      dataIndex: 'createdAt',
      width: '200px',
      render: text => moment(text).format("YYYY/MM/DD")
    },
    {
      title: <>
        <Row>
          <Col span="18" style={{ top: '15px' }}>
            Cargado por
          </Col>
          <Col span="4">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444" stroke="#7084B1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> </svg>
          </Col>
        </Row>
      </>,
      dataIndex: 'user',
      width: '200px',
      render: (_, { user }) => (
        <p>{user && user.username ? user.username : 'Desconocido'}</p>
      ),
    },
    {
      title: <>
        <Row>
          <Col className='ColHeader2' span="18">
            Numero<br />de Registros
          </Col>
          <Col span="4" style={{ top: '16px' }}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444" stroke="#7084B1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> </svg>
          </Col>
        </Row>
      </>,
      dataIndex: 'count',
      width: '200px'
    },
    {
      
      title: (
        <ResponsiveVisibility maxWidth={1050}>
          Acciones
        </ResponsiveVisibility>
      ),
      dataIndex: 'actions',
      render: (_, record) => (
        <Dropdown overlay={() => menu(record)}>
          <a className="optionsButton">
            <svg
              width="3"
              height="10"
              viewBox="0 0 3 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="1.5" cy="1" r="1" fill="#181D2E" />
              <circle cx="1.5" cy="5" r="1" fill="#181D2E" />
              <circle cx="1.5" cy="9" r="1" fill="#181D2E" />
            </svg>
          </a>
        </Dropdown>
      ),
    },
  ]

  const getBasesExternas = async () => {
    try {
      var response = await API.graphql({
        query: `
        query MyQuery ($filter: ModelCLMFilterInput) {
          listCLMS(filter: $filter) {
            items {
              id
              name
              count
              s3Key
              user {
                username
              }
              status
              createdAt
            }
          }
        }
        `
      })
      setData(response.data.listCLMS.items)
    } catch (err) {
      setData([])
    }
    setLoading(false)

  }

  useEffect(() => {
    setLoading(true)
    getBasesExternas()
  }, [isModalOpenDelete, isModalOpen])

  const showModal = () => {
    setIsModalOpen(true)
  };
  const handleOk = () => {
    setLoadingAddExternalBase(true)
    handleUpload()
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsModalOpenDelete(false);
  };
  useEffect(() => {
    setItemsMenu([
      {
        key: "1",
        label: "Eliminar",
      },
      {
        key: "2",
        label: "Deseleccionar",
      }
    ]);
  }, [data])

  const handleUpload = async () => {
    if (!nameData || nameData === '') return window.alert('Ingrese nombre de base externa')
    if (!file) return window.alert('Seleccione un archivo')
    const { name } = file

    try {
      await Storage.put(`${name}`, file)
        .then(response => {
          API.graphql({
            query: `
            mutation MyMutation($input: createCLMCustomInput!) {
              createCLMCustom(input: $input) {
                id
              }
            }`,
            variables: {
              input: {
                name: nameData,
                s3_key: `public/${name}`,
                user_id: userContext.attributes.sub
              }
            }
          })
            .then(data => {
              setIsModalOpen(false)
              setNameData()
              setFile()
              setLoading(true)
              getBasesExternas()
            })
        })
      setLoadingAddExternalBase(false)
      message.loading('Se esta realizando la carga de la BD externa, por favor espere...')
    } catch (error) {
      //console.log("Error uploading file: ", error);
      setLoadingAddExternalBase(false)
    }
  };

  const props = {
    onRemove: file => {
      setFile();
    },
    beforeUpload: file => {
      setFile(file);

      return false;
    },
    multiple: false,
    maxCount: 1,
    accept: '.txt,.txt.gz,.txt.zip,.csv,.csv.zip,.csv.gz'
  }

  return (
    <div className='DataBaseExternalComponent'>
      <Modal
        clasName="ModalGA"
        title="Atención"
        open={isModalOpenDelete}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => deleteBaseExterna(({
              id: idBaseExterna,
            }))}
          >
            Eliminar
          </Button>,
        ]}
      >
        <p>¿Estás seguro que deseas eliminar Base Externa?</p>
      </Modal>
      <Spin spinning={loadingAddExternalBase}>
        <Modal title="Agregar base externa" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <div className="Text1">
            Nombre de base externa
            <Input onChange={({ target }) => setNameData(target.value)} />
            <Upload {...props} >
              <Button >Seleccionar archivo</Button>
            </Upload>
          </div>
        </Modal>
      </Spin>

      <Row className='MenuExports' align="middle">
        <Col span={13} className='SubtituloRegularGA'>

        </Col>
        <Col span={7}>
          <Space direction="vertical">
            <Search
              className='SubtituloRegularGA'
              placeholder="Buscar"
              allowClear
              style={{}}
              onSearch={() => {
                setLoading(true)
                getBasesExternas()
              }}
            />
          </Space>
        </Col>
        <Col span={4}>
          <Button
            className="SubtituloRegularGA Button1"
            type="primary"
            onClick={() => setIsModalOpen(true)}
          >
            Nueva
          </Button>
        </Col>
      </Row>
      <Table
        className='tableDataBaseExternal'
        size="small"
        rowSelection={{
          type: 'radio',
          selectedRowKeys: dataBaseExternal ? [dataBaseExternal] : null,
          //onSelect: onSelectTable,
          onChange: (selectedRowKeys, selectedRows) => {
            setDBExternalName(selectedRows[0]?.name || '')
            const auxSelectedRowKeys = selectedRowKeys[0]
            setDataBaseExternal(auxSelectedRowKeys)
          },
          getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            // Column configuration not to be checked
            name: record.name,
          })
        }
        }
        loading={loading}
        columns={columns}
        dataSource={data}
        rowKey='id'
        scroll={{
          //x: 1500,
          y: 220,
        }}
      />
      {!data.length && <Empty description='Sin datos' />}

    </div>
  );
};

export default DataBaseExternal;
