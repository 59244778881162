import { useState, useEffect } from 'react';

const ResponsiveVisibility = ({ children, maxWidth, maxHeight }) => {
    const [isVisible, setIsVisible] = useState(() => {
        if (maxWidth !== undefined && maxHeight !== undefined) {
            return window.innerWidth > maxWidth && window.innerHeight > maxHeight;
        } else if (maxWidth !== undefined) {
            return window.innerWidth > maxWidth;
        } else if (maxHeight !== undefined) {
            return window.innerHeight > maxHeight;
        }
        return true;
    });

    useEffect(() => {
        const handleResize = () => {
            if (maxWidth !== undefined && maxHeight !== undefined) {
                setIsVisible(window.innerWidth > maxWidth && window.innerHeight > maxHeight);
            } else if (maxWidth !== undefined) {
                setIsVisible(window.innerWidth > maxWidth);
            } else if (maxHeight !== undefined) {
                setIsVisible(window.innerHeight > maxHeight);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [maxWidth, maxHeight]);

    return isVisible ? children : null;
};


export default ResponsiveVisibility;