import { message } from 'antd';

export const showMessage = (type, content, duration = 3, key) => {
  const config = {
    content,
    duration,
    style: {
      marginTop: '7vh',
    },
  };
  if (key) {
    config.key = key;
  }
  if (['info', 'success', 'warning', 'error'].includes(type)) {
    message[type](config);
  } else if (type === 'loading') {
    message.loading({
      key,
      content: 'Cargando indicadores.',
      duration: 0,
      style: {
        marginTop: '7vh',
      },
    });
  }
  else {
    message.error({
      content: 'Tipo de mensaje no válido',
      duration: 3,
      style: {
        marginTop: '7vh',
      },
    });
  }
};